.row {
    padding: .25rem;
}

.span {
    display: inline-block;
    font-weight: bold;
    margin-top: .25rem;
    width: 20rem;
}

.inputFileType {
    width: 20rem;
}

.inputColorType {
    border-radius: 6px;
    margin: .25rem;
}

.inputColorType:hover {
    outline: solid 1px rgba(255, 120, 0, 1);
}

.Label, .label, .labelText {
    float: left;
    vertical-align: top;
    margin: .25rem;    
    width: 15rem;
}

.image {
    border-radius: 8px;
    height: 10rem;
    max-width: 10rem;
}

.tinyInput {
    width: 4rem;
    margin-left: 16rem;
    border-radius: 4px;
    text-align: center;
}

.textarea {
    float: left;
    border-radius: 8px;
    padding: .25rem;
    height: 5rem;
    width: 20rem;
}

@media screen and (max-width: 799px) {
    .Label {
        width: 7rem !important;
    }

    .label, .labelText {
        width: 10rem;
    }

    .span {
        width: 10rem;
    }

    .inputFileType {
        width: 15rem;
    }

    .image {
        height: 8rem;
        max-width: 8rem;
    }

    .tinyInput {
        margin-left: 6rem;
    }

    .textarea {
        width: 15rem;
    }
}