.statusContainer {
    display: inline;
    margin: 0 .15rem;
    padding: .015rem;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.statusLabel {
    display: block;
    float: left;
    margin: 0;
    padding-left: .25rem;
    text-align: left;
    background: rgba(255, 69, 0, .25);
    border: solid 1px rgba(255, 69, 0, .5);
    border-radius: 6px 0 0 6px;
    border-right: solid 1px rgba(255, 69, 0, .5);
    color: rgba(255, 69, 0, 1);
    width: 2rem;
}

.status {
    display: block;
    float: left;
    margin: 0;
    padding-right: .25rem;
    text-align: right;
    border: solid 1px rgba(255, 69, 0, .5);
    border-radius: 0 6px 6px 0;
    color: rgba(255, 120, 0, 1);
    width: 3rem;
}