.card {
    margin-bottom: .25rem;
}

.toggle {
    display: none;
}

.showFull {
    display: inline;
    top: 0;
    right: 0;
    margin: 0;
    padding-right: .5rem;
    height: calc(100vh - 7.25rem);
}

@media only screen and (max-width:767px) and (orientation:portrait) {
    .toggle {
        display: inline;
        position: absolute;
        top: -3.5rem;
        left: .25rem;
        width: 2rem;
        height: 1rem;
        padding: 0;
        padding-bottom: .25rem;
        background: rgba(255, 69, 0, .7);
        color: rgba(255, 120, 0, 1);
        font-weight: bold;
        font-size: 1rem;
        z-index: 1;
    }

    .toggle:hover {
        background: rgba(255, 69, 0, .9);
        color: rgba(255, 120, 0, 1);
    }

    .showFull {
        display: none;
    }
    
    .show {
        display: inline;
        top: -3.75rem;
        left: 0;
        width: 16rem;
        margin: .25rem;
        padding: 2.5rem .5rem;
        padding-bottom: 1.5rem;
        height: calc(100vh - 11.75rem);
        background: rgba(25, 25, 25, 1);
        border-radius: 12px;
        outline: solid 1px rgba(255, 120, 0, .8);
        animation: expand linear .15s 1;
    }
    
    .hide {
        display: inline;
        top: -3.75rem;
        left: -18rem;
        width: 16rem;
        margin: .25rem;
        padding: 2.5rem .5rem;
        padding-bottom: 1.5rem;
        height: calc(100vh - 11.75rem);
        background: rgba(25, 25, 25, 1);
        border-radius: 12px;
        outline: solid 1px rgba(255, 120, 0, .8);
        animation: contract linear .15s 1;
    }
}

@keyframes expand {
    from {
        left: -18rem;
    }
    to {
        left: 0;
    }
}

@keyframes contract {
    from {
        left: 0;
    }
    to {
        left: -18rem;
    }
}