.BodyHeader {
    display: inline-block;
    font-family: "ShackleItalic";
    font-size: .9rem;
    font-weight: bold;
    letter-spacing: .065rem;
    width: 100%;
    border-bottom: solid 1px rgba(255, 69, 0, .8);
    color: rgba(255, 69, 0, .8);
    margin: .25rem;
    margin-left: 0;
}