.header {
    width: 100%;
    height: 1rem;
}

.headerText a:link, .headerText a:visited {
    float: right;
    margin-top: .25rem;
    margin-inline-end: 1rem;
    font-family: "ShackleRegular";
    font-size: .8rem;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: .065rem;
    color: rgba(255, 69, 0, .8);
    z-index: 1;
}

.headerText a:hover {
    color: rgba(255, 69, 0, 1);
}

.top {
    height: 3rem;
    width: 100%;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.topText {
    position: relative;
    float: left;
    top: .25rem;
    margin-left: 1rem;
    font-family: "Kochire";
    font-size: 2rem;
    color: orangered;
}

.logo {
    position: relative;
    float: left;
    top: .25rem;
    margin-left: .25rem;
}

.logo img {
    height: 2.7rem;
    width: 2.8rem;
}

.navbar {
    position: absolute;
    right: 0;
    top: 1.65rem;
    z-index: 10;
}

.navLink {
    display: block;
    text-align: center;
    padding: .25rem;
    float: right;
    font-family: "ShackleRegular";
    letter-spacing: .065rem;
    color: rgba(255, 69, 0, .8);
    text-decoration: none;
    height: 1rem;
    width: 5rem;
    z-index: 1;
}

.navLink:hover, .navLink:active {
    color: rgba(255, 69, 0, 1);
}

.link {
    padding: .25;
    margin: 0;
    text-align: center;
    background: transparent;
    border: none;
    font-weight: bold;
    font-size: 1rem;
    text-decoration: none;
    float: right;
    color: rgba(255, 69, 0, 0.8);
    width: 6.5rem;
    z-index: 1;
}

.link:hover {
    background: transparent;
    color: rgba(255, 69, 0, 1);
}

@media screen and (max-width: 550px) {
    .navbar {
        margin-top: 1.25rem;
    }
}