.body {
    display: block;
    position: absolute;
    top: 4rem;
    right: 0;
    width: 16.75rem;
    height: calc(100vh - 6.5rem);
    overflow: auto;
    -ms-overflow-style: none;
    padding-right: .25rem;
}

@media only screen and (max-width:767px) and (orientation:portrait) {
    .body {
        left: .5rem;
        top: 0;
        background-color: pink;
        height: calc(100vh - 6.5rem);
        padding: 2.5rem 0;
    }
}