.note {
    margin: .5rem 0;
}

.noteHeader {
    font-family: "ShackleItalic";
    font-size: .8rem;
    font-weight: 400;
    letter-spacing: .065rem;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: none;
    padding-top: .25rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.noteBody {
    font-family: "ShackleRegular";
    font-size: .8rem;
    font-weight: 200;
    letter-spacing: .065rem;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: .25rem;
}

.infotype-header {
    background: rgba(145, 145, 255, .8);
    color: rgba(0, 0, 0, .8);
    border: solid 1px(145, 145, 255, 1);
}

.infotype-body {
    background: rgba(30, 30, 30, .8);
    color: rgba(145, 145, 255, .8);
    border: solid 1px rgba(145, 145, 255, 1);
    border-top: none;
}

.successtype-header {
    background: rgba(105, 255, 0, .8);
    color: rgba(0, 0, 0, .8);
    border: solid 1px rgba(105, 255, 0, 1);
}

.successtype-body {
    background: rgba(30, 30, 30, .8);
    color: rgba(105, 255, 105, .8);
    border: solid 1px rgba(105, 255, 105, 1);
    border-top: none;
}

.warningtype-header {
    background: rgba(255, 255, 0, .8);
    color: rgba(0, 0, 0, .8);
    border: solid 1px rgba(255, 255, 0, 1);
}

.warningtype-body {
    background: rgba(30, 30, 30, .8);
    color: rgba(255, 255, 105, .8);
    border: solid 1px rgba(255, 255, 105, 1);
    border-top: none;
}

.errortype-header {
    background: rgba(255, 105, 105, .8);
    color: rgba(0, 0, 0, .8);
    border: solid 1px rgba(255, 105, 105, 1);
}

.errortype-body {
    background: rgba(30, 30, 30, .8);
    color: rgba(255, 105, 105, .8);
    border: solid 1px rgba(255, 105, 105, 1);
    border-top: none;
}