.main {
    position: absolute;
    top: 4rem;
    color: rgba(255, 69, 0, 1);
    font-size: 1.2rem;
    width: calc(100vw - 20rem);
    height: calc(100vh - 6rem);
}

.scrollable {
    display: inline-block;
    overflow: scroll;
}

.noScrollbars {
    -ms-overflow-style: none;
}

.noScrollbars::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width:767px) and (orientation:portrait) {
    .main {
        position: relative;
        top: 1.25rem;
        width: calc(100vw - 2.75rem);
    }
}