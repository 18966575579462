.statusBar {
    position: absolute;
    left: 3.5rem;
    bottom: 1.5rem;
    padding: 0;
    margin: 0;
    display: flex;
    font-size: .7rem;
    font-weight: bold;
    letter-spacing: .065rem;
}

@media screen and (max-width:767px) and (orientation:portrait) {
    .statusHideIfTiny {
        display: none;
    }
}