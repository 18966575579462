.communitySettings {
    position: absolute;
    height: calc(100vh - 9.5rem);
    width: calc(100vw - 23rem);
    overflow-x: hidden;
    overflow-y: auto;
}


.sticky {
    position: sticky;
    top: 0;
    padding: 0;
    margin: 0;
    background: rgba(0, 0, 0, .75);
}

@media screen and (max-width: 799px) and (orientation: portrait) {
    .communitySettings {
        width: calc(100vw - 6rem);
    }
}
