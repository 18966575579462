.App {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #000000, #630000 80%, #000000 );
  width: 100vw;
  height: 100vh;
  -ms-overflow-style: none;
}

.error {
  position: absolute;
  top: 4rem;
  height: calc(100vh - 7.25rem);
}

.horizRowContainer {
  display: flex;
}

.floatingHolder {
  display: inline;
  position: absolute;
  top: 4rem;
  right: 0;
}

.status {
  display: inline;
}

@media only screen and (max-width:767px) and (orientation:portrait) {
  .error {
    position: relative;
    top: 1.25rem;
  }
  
  .horizRowContainer {
    position: absolute;
    left: 0;
  }
  
  .floatingHolder {
    display: inline;
    position: absolute;
    top: 4.5rem;
    left: 0;
    width: 18rem;
    height: 0;
    z-index: 10;
  }
}