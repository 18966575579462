.card {
    background: rgba(0, 0, 0, .75);
    color: rgba(255, 120, 50, .8);
    font-family: "ShackleRegular";
    letter-spacing: .065rem;
    font-size: .825rem;
    text-align: left;
    border: solid 1px rgba(255, 69, 0, 0.8);
    border-radius: 8px;
}

.toggle {
    float: right;
    font-size: 1rem;
    margin-bottom: .25rem;
}

.minified {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    height: 1.25rem;
}

.header {
    font-family: "ShackleItalic";
    letter-spacing: .065rem;
    font-size: .85rem;
    font-weight: 200;
    background: rgba(255, 69, 0, .4);
    color: rgba(255, 69, 0, .9);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: .25rem;
    padding-bottom: 0;
    margin-bottom: .25rem;
    width: calc(100% - .5rem);
    height: 1rem;
}

.with-cursor {
    cursor: pointer;
}

.with-cursor:hover {
    background: rgba(255, 69, 0, .6);
    color: rgba(255, 69, 0, 1);
}

.opened {
    animation: slide-down 300ms ease-out forwards;
}

.closed {
    animation: slide-up 300ms ease-in forwards;
}

.bodyElements {
    padding: 0rem;
}

.noSelect {
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

@keyframes slide-down {
    from {
        height: 0;
    }
    to {
        height: 100%;
    }
}

@keyframes slide-up {
    from {
        height: 100%;
    }
    to {
        height: 0;
    }
}