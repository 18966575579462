.card {
    margin-bottom: .25rem;
}

.header {
    margin: .25rem .5rem;
    border: none;
}

.updateBody {
    padding: .25rem;
    padding-bottom: .5rem;
    font-size: .9rem;
}