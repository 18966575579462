.NavCard {
    background: rgba(75, 75, 75, .8);
    margin: .125rem;
    padding: .25rem 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.NavCard:hover {
    outline: solid 1px rgba(255, 120, 0, .8);
    cursor: pointer;
}

.header {
    padding-left: .5rem;
    font-size: 1rem;
    color: rgba(255, 69, 0, 1);
}

.body {
    padding-left: 1rem;
    font-size: .8rem;
    color: rgba(255, 120, 0, 1);
}

.noSelect {
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.colLeft {
    width: 15rem;
}

.colRight {
    width: 5rem;
}

.icon {
    float: right;
    margin-top: .25rem;
    margin-right: .5rem;
    font-size: 1.4rem;
    color: rgba(255, 120, 0, .8);
}

.selected {
    background: rgba(255, 69, 0, .25);
}