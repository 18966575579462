.formRow {
    clear: both;
    min-height: 1rem;
    display: block;
}

.note {
    margin: 0 .5rem;
}

.labelText {
    width: 10rem;
}

.inputStyle {
    width: 12rem;
}

.primaryBtn {
    background: rgba(255, 69, 0, .5);
    color: rgba(255, 120, 50, .8);
}

.welcome {
    padding: .25rem;
    padding-bottom: .5rem;
    margin-top: .15rem;
}

.name {
    font-weight: 400;
    color: rgba(255, 69, 0, 1);
}

.link {
    position: absolute;
    top: .5;
    right: 0;
    background: transparent;
    border: none;
    font-weight: bold;
    font-size: .8rem;
    color: rgba(255, 69, 0, 0.8);
    width: 10rem;
    text-decoration: none;
}

.link:hover {
    background: transparent;
    color: rgba(255, 69, 0, 1);
}

.errorMessage {
    text-align: center;
    padding-right: 1rem;
    font-size: .7rem;
    font-weight: bold;
    color: rgba(255, 69, 0, 1);
}

@media screen and (min-width: 800px) {
    .inputStyle {
        width: 20rem;
    }
}