.footer {
    font-family: "ShackleItalic";
    letter-spacing: .065rem;
    color: rgba(255, 69, 0, .8);
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    font-size: .8rem;
    width: 100vw;
    height: 1.25rem;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}