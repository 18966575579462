.userSettings {
    position: absolute;
    height: calc(100vh - 9.5rem);
    width: calc(100vw - 23rem);
    overflow-x: hidden;
    overflow-y: auto;
}

.sticky {
    position: sticky;
    top: 0;
    padding: 0;
    margin: 0;
    background: rgba(0, 0, 0, .75);
}

.primaryBtn {
    background: rgba(255, 69, 0, .5);
    color: rgba(255, 120, 50, .8);
}

.formRow {
    clear: both;
    min-height: 1.5rem;
    display: block;
}

.required {
    font-weight: bold;
    color: rgba(255, 120, 0, 1);
}

.icon {
    position: relative;
    margin: 0;
    padding: 0;
    height: 1rem;
    top: -1.75rem;
    left: 4rem;
}

.redHand {
    color: rgba(255, 69, 0, 1);
}

.indentedInput {
    position: relative;
    width: 12rem;
    left: 2rem;
}

.labelText {
    width: 10rem;
}

.label {
    float: left;
    margin: .25rem;    
    width: 10rem;
}

.inputStyle {
    width: 15rem;
}

.tinyInput {
    width: 4rem;
    margin-left: 6rem;
    border-radius: 4px;
    text-align: center;
}

.textarea {
    float: left;
    border-radius: 8px;
    padding: .25rem;
    height: 5rem;
    width: 15rem;
}

.textarea:hover {
    border: solid 1px rgba(255, 120, 0, 1);
    cursor: text;
}

.link {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: .8rem;
    color: rgba(255, 69, 0, 0.8);
    width: 6.5rem;
    text-decoration: none;
}

.link:hover {
    background: transparent;
    color: rgba(255, 69, 0, 1);
}

@media screen and (max-width: 799px) and (orientation: portrait) {
    .userSettings {
        width: calc(100vw - 6rem);
    }
}

@media screen and (min-width: 800px) {
    .userSettings {
        width: calc(100vw - 23rem);
    }

    .label, .labelText {
        width: 15rem;
    }

    .inputStyle, .textarea {
        width: 20rem;
    }

    .tinyInput {
        margin-left: 16rem;
    }

    .indentedInput {
        width: 16rem;
    }
}