.button {
    width: 5rem;
    margin: .25rem;
    padding: .25rem;
    border-radius: 12px;
    border: none;
    background: rgba(255, 69, 0, .25);
    color: rgba(255, 120, 50, .8);
    font-family: "ShackleRegular";
    font-size: .9rem;
    letter-spacing: .065rem;
    cursor: pointer;
}

.button:hover {
    background: rgba(255, 69, 0, .8);
    color: rgba(255, 120, 50, 1);
}

.button:disabled {
    cursor: default;
    background: rgba(255, 69, 0, .2);
    color: rgba(255, 120, 0, .5);
}