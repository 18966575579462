.defaultPage {
    height: calc(100vh - 1rem);
    width: calc(100vw - 22rem);
    margin: 1rem;
    margin-top: 0;
    border-radius: 8px;
    border: solid 1px rgba(255, 69, 0, .8);
    background: rgba(0, 0, 0, .75);
    height: calc(100vh - 6em);
}

.header {
    background: rgba(0, 0, 0, 1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.headerText {
    text-align: start;
    padding: .25rem;
    margin-left: .5rem;
    margin-right: .5rem;
    font-family: 'ShackleItalic';
    letter-spacing: .065rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: rgba(255, 69, 0, 1);
}

.body {
    display: block;
    height: calc(100vh - 9.15rem);
    width: calc(100vw - 23rem);
    padding: 0 .5rem;
    font-family: 'ShackleRegular';
    letter-spacing: .065rem;
    font-size: .95rem;
    color: rgba(255, 120, 50, .8);
}

.noScrollbars {
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.noSelect {
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

@media only screen and (max-width:767px) and (orientation:portrait) {
    .defaultPage {
        width: calc(100vw - 5rem);
    }
    
    .body {
        width: calc(100vw - 6rem);
    }
}