.container {
    display: flex;
    justify-content: left;
    align-items: top;
}

.Label, .Input {
    float: left;
    margin: .25rem;
}

.Label {
    width: 10rem;
}

.Label:hover {
    text-decoration: underline;
    cursor: pointer;
}

.required {
    font-weight: bold;
    color: rgba(255, 120, 50, 1);
}

.Input {
    border-radius: 8px;
    border: solid 1px transparent;
    padding: .25rem;
    height: 1rem;
    background: rgba(255, 255, 255, .9);
}

.Input:disabled {
    background: rgba(155, 155, 155, 1);
}

.Input[type='radio'],
.Input[type='checkbox'] {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    accent-color: rgba(255, 120, 0, .6);
}

.Input[type='file'] {
    font-family: "ShackleRegular";
    font-style: italic;
    font-weight: bold;
    letter-spacing: .065rem;
}

.Input[type='file']::file-selector-button {
    background: rgba(255, 69, 0, .8);
    color: rgba(33, 33, 33, .8);
    cursor: pointer;
    border: none;
    border-radius: 8px;
}

.input[type='color'] {
    border: none;
}

.Input:hover {
    outline: solid 1px rgba(255, 180, 30, .6);
}

.Input:focus {
    background: rgba(155, 155, 255, 1);
}

.InputError {
    background: rgba(255, 155, 155, .8);
}

.InputValid {
    background: rgba(155, 255, 155, .8);
}

::-webkit-input-placeholder { /* Edge */
    font-family: 'ShackleItalic';
    color: rgba(175, 20, 0, 1);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'ShackleItalic';
    color: rgba(175, 20, 0, 1);
}

::placeholder {
    font-family: 'ShackleItalic';
    color: rgba(175, 20, 0, 1);
}