.left {
    padding-left: .25rem;
    width: 2.5rem;
    height: calc(100vh - 4.5rem);
}

.menuButton {
    position: relative;
    top: calc(100% / 2 - .75rem);
    left: calc(100% / 2 - .85rem);
    font-size: 1.5rem;
    color: rgba(255, 69, 0, .8);
}

.svgContainer {
    width: 2.5rem;
    height: 2.5rem;
}

.menuButton:hover, .menuButton:active {
    color: rgba(255, 69, 0, 1);
}

.scrollable {
    display: inline-block;
    overflow: scroll;
}

.noScrollbars {
    -ms-overflow-style: none;
}

.noScrollbars::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width:767px) and (orientation:portrait) {
    .left {
        padding-top: 2rem;
        height: calc(100vh - 8.5rem);
    }
}